import React from 'react';
import Helmet from 'react-helmet';
import settings from '../../settings';
import {graphql, useStaticQuery} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";

//	import snow_image_01 from './snow.01.jpeg';
//	import snow_image_02 from './snow.02.jpeg';

export default function() {

	const {
		imageMobile_snow_01,	imageDesktop_snow_01,
		imageMobile_snow_02,	imageDesktop_snow_02,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_snow_01: file(relativePath: { eq: "pages/winter/snow.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_snow_01: file(relativePath: { eq: "pages/winter/snow.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:1280) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_snow_02: file(relativePath: { eq: "pages/winter/snow.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_snow_02: file(relativePath: { eq: "pages/winter/snow.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:1280) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
	  );

	const image_sources	=	{

		snow_01	:	[
			imageMobile_snow_01.childImageSharp.fluid,
			{
			...imageDesktop_snow_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],
		snow_02	:	[
			imageMobile_snow_02.childImageSharp.fluid,
			{
			...imageDesktop_snow_02.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

	};

	return (
		<Layout>
			<Helmet>
				<title>Winter Services • Blackstone VT</title>
				<link rel="canonical" href={`${settings.host.url}/winter`} />
				<meta
					name="description"
					content="Winter services in Southern Vermont for day-after storm snow removal. Clearing snow from decks, paths, and walkways as well as roof raking."
				/>
			</Helmet>
			<Banner bgImageSources={image_sources.snow_02} isH1={true} styles={{
				color: '#333',
				fontWeight: '300',
				textShadow: '1px 1px 9px #fff',
			}}>Winter Services</Banner>
			<BackgroundImage
				alt="Snow Flakes Falling"
				fluid={image_sources.snow_01}
				style={{
					backgroundPosition: '50% 50%',
					backgroundSize: 'cover',
					borderTop: '3px solid RGB(255,205,0)',
					height: '100%',
				}}
			>
				<div style={{
					backgroundColor: 'White',
					opacity: 0.5,
					position: 'absolute',
						top: 0,
						right: 0,
						bottom: 0,
						left: 0,
				}}></div>
				<div className="container" style={{
						fontWeight: '300',
						position: 'relative',
						textShadow: '1px 1px 75px #000',
					}}>
					<div className="column">
						<div className="row">
							<h3 className="mx-auto my-5">Winter is tough, we can help.</h3>
						</div>
						<div className="row col-lg-6 mx-auto" style={{
								color: '#222',
								fontSize: '20px',
								textShadow: 'white 1px 1px 3px',
							}}>
							<div className="" style={{
									fontSize: '20px',
								}}>
								<p>
									We're working to grow our palette of offerings during the winter. We're happy to offer the following <i>'day-after'</i> storm services for snow removal:
								</p>
							</div>
							<ul className="px-0 mx-auto" style={{
								color: '#393939',
								listStyle: 'none',
							}}>
								<li><span role="img" aria-label="Snowflake">❄️</span> Roof Raking</li>
								<li><span role="img" aria-label="Snowflake">❄️</span> Walkways / Paths</li>
								<li><span role="img" aria-label="Snowflake">❄️</span> Decks / Porches</li>
								<li><span role="img" aria-label="Snowflake">❄️</span> Propane Tanks</li>
							</ul>
						</div>
					</div>
				</div>
			</BackgroundImage>
		</Layout>
	);
}
